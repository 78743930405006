.root {
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
  object-fit: cover;
  object-position: center center;
  background-color: var(--g--color-jet-black);
  border-radius: inherit;

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  &[data-playing='true'] {
    opacity: 1;
    transition-duration: calc(var(--g--anim-duration) * 1.5);
  }
}
