.root {
  pointer-events: none;

  position: absolute;
  inset: var(--card--edge-padding) var(--card--edge-padding) var(--card--title-height);
  transform: translateY(0) scale(var(--card--popover-start-scale));

  border-radius: calc(var(--card--border-radius) + (var(--card--popover-padding) / 2));

  transition: transform var(--g--anim-duration) var(--g--anim-easing);

  &.isCardActive.isVisible {
    pointer-events: auto;
    transform: translateY(var(--card--popover-top-offset)) scale(1);
    transition: transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);
  }
}

.container {
  position: absolute;
  top: var(--card--popover-top);
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  gap: var(--card--popover-content-margin);

  width: var(--card--popover-width);
  margin: 0;
  padding: var(--card--popover-padding);
  padding-top: calc(
    (var(--card--popover-padding) * 0.5) + var(--card--popover-card-height) +
      var(--card--popover-content-margin)
  );

  border-radius: inherit;
}

.bg {
  position: absolute;
  inset: 0;

  opacity: 0;
  background: var(--g--glass-dark-900);
  backdrop-filter: blur(40px);
  border-radius: inherit;
  box-shadow:
    var(--g--highlight-md),
    0 0 0 1.5px var(--g--glass-light-100),
    var(--g--shadow-xl);

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  .isCardActive.isVisible & {
    opacity: 1;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  gap: var(--card--popover-content-margin);

  width: 100%;

  opacity: 0;

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  .isCardActive.isVisible & {
    opacity: 1;
  }
}

.title {
  position: relative;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;

  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--g--color-white);
  text-shadow: 0 1px 2px var(--g--glass-dark-100);
}

.description {
  position: relative;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;

  margin-top: -6px;

  font-size: 13px;
  font-weight: 400;
  line-height: 1.25;
  color: var(--g--color-grey);
  text-shadow: 0 1px 2px var(--g--glass-dark-100);
  word-break: break-word;
}

.tags {
  position: relative;

  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  justify-content: flex-start;

  width: calc(100% + 8px);

  /* 25px is the height of the tag and 8px is the total vertical padding */
  height: calc(25px + 8px);
  margin: -4px;
  padding: 4px;
}

.tag.tag {
  overflow: hidden;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;

  max-width: 100%;

  text-overflow: ellipsis;
}

.actions {
  position: relative;
  display: flex;
  gap: var(--card--action-gap);
}

.launchAction {
  flex-grow: 1;
  flex-shrink: 1;
}

.infoAction {
  flex-grow: 0;
  flex-shrink: 0;
}
