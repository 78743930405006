.root {
  --card--border-radius: var(--g--card-border-radius);
  --card--edge-padding: var(--g--card-gap);
  --card--action-gap: var(--g--card-action-gap);
  --card--action-height: var(--g--card-action-height);
  --card--title-height: calc(14px + (2 * var(--g--margin-sm)));
  --card--aspect-ratio: var(--g--card-landscape-medium-aspect-ratio);
  --card--width: var(--g--card-landscape-medium-width);
  --card--height: var(--g--card-landscape-medium-height);
  --card--padded-width: calc(var(--card--width) + 2 * var(--card--edge-padding));
  --card--padded-height: calc(
    var(--card--height) + var(--card--title-height) + var(--card--edge-padding)
  );
  --card--width-unitless: var(--g--card-landscape-medium-width-unitless);
  --card--popover-padding: 16px;
  --card--popover-content-margin: 16px;
  --card--popover-top-offset: -20px;
  --card--popover-width: calc(var(--card--width) + 48px);
  --card--popover-card-width: calc(var(--card--popover-width) - var(--card--popover-padding));
  --card--popover-card-height: calc(var(--card--popover-card-width) / var(--card--aspect-ratio));
  --card--popover-top: calc(
    (var(--card--popover-padding) * -0.5) +
      ((var(--card--popover-card-height) - var(--card--height)) * -0.5)
  );
  --card--popover-start-scale: calc(
    var(--card--width-unitless) / (var(--card--width-unitless) + 32)
  );
  --card--image-expanded-scale: calc(
    (var(--card--width-unitless) + 32) / var(--card--width-unitless)
  );

  pointer-events: none;
  content-visibility: auto;
  contain-intrinsic-size: var(--card--padded-width) var(--card--padded-height);

  position: relative;
  z-index: 1;

  contain: strict;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;

  width: var(--card--padded-width);
  height: var(--card--padded-height);
  margin: calc(var(--card--edge-padding) * -1);
  margin-bottom: 0;

  &.landscapeOrientation.mediumSize {
    --card--aspect-ratio: var(--g--card-landscape-medium-aspect-ratio);
    --card--width: var(--g--card-landscape-medium-width);
    --card--height: var(--g--card-landscape-medium-height);
    --card--width-unitless: var(--g--card-landscape-medium-width-unitless);
  }

  &.landscapeOrientation.largeSize {
    --card--aspect-ratio: var(--g--card-landscape-large-aspect-ratio);
    --card--width: var(--g--card-landscape-large-width);
    --card--height: var(--g--card-landscape-large-height);
    --card--width-unitless: var(--g--card-landscape-large-width-unitless);
  }

  &.portraitOrientation.mediumSize {
    --card--aspect-ratio: var(--g--card-portrait-medium-aspect-ratio);
    --card--width: var(--g--card-portrait-medium-width);
    --card--height: var(--g--card-portrait-medium-height);
    --card--width-unitless: var(--g--card-portrait-medium-width-unitless);
  }

  &.portraitOrientation.largeSize {
    --card--aspect-ratio: var(--g--card-portrait-large-aspect-ratio);
    --card--width: var(--g--card-portrait-large-width);
    --card--height: var(--g--card-portrait-large-height);
    --card--width-unitless: var(--g--card-portrait-large-width-unitless);
  }

  &.isPopoverVisible,
  &.isActive {
    content-visibility: visible;
    contain: none;
  }

  &.isPopoverVisible {
    z-index: 9;
  }

  &.isActive {
    z-index: 10;
  }
}

.focusTarget {
  position: absolute;
  top: var(--card--edge-padding);
  left: var(--card--edge-padding);

  width: var(--card--width);
  height: var(--card--height);

  visibility: hidden;
}

.scrollTarget {
  position: absolute;
  top: calc(var(--card--popover-top) + var(--card--popover-top-offset) + var(--card--edge-padding));
  right: var(--card--edge-padding);
  left: var(--card--edge-padding);

  /*
  Computed max-height of the popover content:
  + top padding: calc(var(--card--popover-padding) / 2)
  + popover card height: var(--card--popover-card-height)
  + margin: var(--card--popover-content-margin)
  + title height: 18px
  + margin: calc(var(--card--popover-content-margin) - 6px)
  + description height: 49px
  + margin: var(--card--popover-content-margin)
  + tags: 25px
  + margin: var(--card--popover-content-margin)
  + actions height: var(--card--action-height)
  + bottom padding: var(--card--popover-padding)
  */
  height: calc(
    (var(--card--popover-padding) / 2) + var(--card--popover-card-height) +
      var(--card--popover-content-margin) + 18px + (var(--card--popover-content-margin) - 6px) +
      49px + var(--card--popover-content-margin) + 25px + var(--card--popover-content-margin) +
      var(--card--action-height) + var(--card--popover-padding)
  );

  visibility: hidden;
}

.title {
  pointer-events: auto;
  contain-intrinsic-size: var(--card--width) var(--card--title-height);

  position: absolute;
  bottom: 0;
  left: var(--card--edge-padding);

  contain: strict;
  overflow: hidden;
  display: block;

  width: var(--card--width);
  height: var(--card--title-height);

  font-size: 14px;
  font-weight: 500;
  line-height: var(--card--title-height);
  color: var(--g--color-grey);
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  .isActive & {
    opacity: 0;
    transition-duration: calc(var(--g--anim-duration) / 2);
  }
}

.imageContainer {
  pointer-events: auto;
  contain-intrinsic-size: var(--card--width) var(--card--height);

  position: absolute;
  top: var(--card--edge-padding);
  left: var(--card--edge-padding);
  transform: translateY(0) scale(1);

  contain: strict;

  width: var(--card--width);
  height: var(--card--height);

  border-radius: var(--card--border-radius);
  box-shadow: var(--g--shadow-md);

  transition: transform var(--g--anim-duration) var(--g--anim-easing);

  .isActive.isPopoverVisible & {
    transform: translateY(var(--card--popover-top-offset)) scale(var(--card--image-expanded-scale));
    transition: transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);
  }
}

.image {
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center center;
  border-radius: inherit;
}

.imageLink {
  position: absolute;
  inset: 0;

  color: transparent;

  background: transparent;
  border-radius: inherit;
  outline: none;
  box-shadow: var(--g--inset-highlight-md);
}
