.root {
  position: relative;

  background: var(--g--color-black);
  background-image: radial-gradient(18% 28% at 12% 38%, #731340 7%, #073aff00 100%),
    radial-gradient(18% 28% at 18% 98%, #731340 6%, #073aff00 100%),
    radial-gradient(42% 53% at 55% 59%, #731340 7%, #073aff00 100%),
    radial-gradient(42% 53% at 27% 74%, #150011 7%, #073aff00 100%),
    radial-gradient(18% 28% at 34% 108%, #731340 7%, #073aff00 100%),
    radial-gradient(31% 43% at 7% 98%, #150011 24%, #073aff00 100%),
    radial-gradient(21% 37% at 73% 64%, #150011 24%, #073aff00 100%),
    radial-gradient(35% 56% at 91% 74%, #731340 9%, #073aff00 100%),
    linear-gradient(125deg, #150011 2%, #150011 100%);
  background-repeat: no-repeat;
  background-position:
    0 0,
    0 0,
    0 0,
    0 0,
    0 0,
    0 0,
    0 0,
    0 0,
    0 0;
  background-size: 100% 100%;
}
