.root {
  --spacer--multiplier: 1;

  position: relative;
  width: 100%;

  &.xsSize {
    height: calc(var(--g--margin-xs) * var(--spacer--multiplier));
  }

  &.smSize {
    height: calc(var(--g--margin-sm) * var(--spacer--multiplier));
  }

  &.mdSize {
    height: calc(var(--g--margin-md) * var(--spacer--multiplier));
  }

  &.lgSize {
    height: calc(var(--g--margin-lg) * var(--spacer--multiplier));
  }

  &.xlSize {
    height: calc(var(--g--margin-xl) * var(--spacer--multiplier));
  }

  &.viewportSize {
    height: calc(var(--g--margin-viewport) * var(--spacer--multiplier));
  }
}
