.root {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: left;

  margin: 0 var(--g--margin-viewport);
  padding: 0;

  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  text-shadow: 0 1px 2px var(--g--glass-dark-100);
  white-space: nowrap;

  outline: 0;

  &.largeSize {
    font-size: 52px;
    font-weight: 300;
    letter-spacing: -0.5px;
  }

  & > svg {
    flex-grow: 0;
    flex-shrink: 0;

    width: 12px;
    height: 12px;
    margin-right: var(--g--margin-sm);

    color: var(--g--color-ray-orange);

    .largeSize & {
      width: 24px;
      height: 24px;
    }
  }
}

.title {
  overflow: hidden;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 1;

  color: inherit;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  border-radius: calc(var(--g--button-border-radius) / 2);
}

a.title {
  outline: 0;
  transition: color var(--g--button-anim-duration) var(--g--button-anim-easing);

  html[data-nav-device='mouse'] &:hover {
    color: var(--g--color-ray-orange);
  }

  html[data-nav-device='keyboard'] &:focus {
    color: var(--g--color-ray-orange);
    outline: 1px solid var(--g--color-white);
    outline-offset: 4px;
  }
}
