.root {
  --card-list--header-height: calc(18px * 1.2);
  --card-list--scroller-padding-top: var(--g--margin-md);
  --card-list--scroller-padding-bottom: calc(var(--g--margin-md) * 2.5);
  --card-list--scroller-indicator-bottom: calc(100% + 3px);
  --card-list--card-height: var(--g--card-landscape-medium-height);
  --card-list--height: calc(
    var(--card-list--header-height) + var(--card-list--scroller-padding-top) +
      var(--card-list--scroller-padding-bottom) + var(--card-list--card-height)
  );

  content-visibility: hidden;
  contain-intrinsic-size: 100% var(--card-list--height);

  position: relative;

  contain: strict;

  height: var(--card-list--height);

  &.largeHeaderSize {
    --card-list--header-height: calc(52px * 1.2);
  }

  &.landscapeCardOrientation.mediumCardSize {
    --card-list--card-height: var(--g--card-landscape-medium-height);
  }

  &.landscapeCardOrientation.largeCardSize {
    --card-list--card-height: var(--g--card-landscape-large-height);
  }

  &.portraitCardOrientation.mediumCardSize {
    --card-list--card-height: var(--g--card-portrait-medium-height);
  }

  &.portraitCardOrientation.largeCardSize {
    --card-list--card-height: var(--g--card-portrait-large-height);
  }

  &.isNearViewport {
    content-visibility: visible;
  }

  &:has([data-card-popup-visible='true'], [data-batch-scrolling='true']) {
    contain: none;
  }
}

.scrollToTarget {
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.header {
  z-index: 1;
  top: 4px;
}

.scroller.scroller {
  --card-scroller--padding-top: var(--card-list--scroller-padding-top);
  --card-scroller--padding-bottom: var(--card-list--scroller-padding-bottom);
  --card-scroller--indicator-bottom: var(--card-list--scroller-indicator-bottom);
  --card-scroller--expand-top: 50px;
  --card-scroller--expand-bottom: 200px;
}
