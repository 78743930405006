.root {
  --card-scroller--expand-top: 0px;
  --card-scroller--expand-bottom: 0px;
  --card-scroller--padding-x: var(--g--margin-viewport);
  --card-scroller--padding-top: var(--g--margin-md);
  --card-scroller--padding-bottom: var(--g--margin-md);
  --card-scroller--indicator-width: 80px;
  --card-scroller--indicator-height: 4px;
  --card-scroller--indicator-left: auto;
  --card-scroller--indicator-right: var(--card-scroller--padding-x, var(--g--margin-viewport));
  --card-scroller--indicator-top: auto;
  --card-scroller--indicator-bottom: 100%;
  --card-scroller--card-width: var(--g--card-landscape-medium-width);
  --card-scroller--card-height: var(--g--card-landscape-medium-height);
  --card-scroller--card-count: var(--g--card-landscape-medium-count);
  --card-scroller--width: 100%;
  --card-scroller--height: calc(
    var(--card-scroller--card-height) + var(--card-scroller--padding-top) +
      var(--card-scroller--padding-bottom)
  );

  /* These are computed with JS */
  --card-scroller--item-count: 0;
  --card-scroller--indicator-scroll-factor: 0;
  --card-scroller--indicator-thumb-position: 0;

  position: relative;
  display: flex;
  flex-flow: row nowrap;

  &.mediumCardSize.landscapeCardOrientation {
    --card-scroller--card-width: var(--g--card-landscape-medium-width);
    --card-scroller--card-height: var(--g--card-landscape-medium-height);
    --card-scroller--card-count: var(--g--card-landscape-medium-count);
  }

  &.mediumCardSize.portraitCardOrientation {
    --card-scroller--card-width: var(--g--card-portrait-medium-width);
    --card-scroller--card-height: var(--g--card-portrait-medium-height);
    --card-scroller--card-count: var(--g--card-portrait-medium-count);
  }

  &.largeCardSize.landscapeCardOrientation {
    --card-scroller--card-width: var(--g--card-landscape-large-width);
    --card-scroller--card-height: var(--g--card-landscape-large-height);
    --card-scroller--card-count: var(--g--card-landscape-large-count);
  }

  &.largeCardSize.portraitCardOrientation {
    --card-scroller--card-width: var(--g--card-portrait-large-width);
    --card-scroller--card-height: var(--g--card-portrait-large-height);
    --card-scroller--card-count: var(--g--card-portrait-large-count);
  }
}

.scroller {
  pointer-events: none;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  scroll-padding-left: var(--card-scroller--padding-x);
  scroll-snap-type: x mandatory;
  contain-intrinsic-size: var(--card-scroller--width)
    calc(
      var(--card-scroller--height) + var(--card-scroller--expand-top) +
        var(--card-scroller--expand-bottom)
    );

  position: relative;

  contain: strict;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  flex-flow: row nowrap;

  width: var(--card-scroller--width);
  height: calc(
    var(--card-scroller--height) + var(--card-scroller--expand-top) +
      var(--card-scroller--expand-bottom)
  );
  margin-top: calc(var(--card-scroller--expand-top) * -1);
  margin-bottom: calc(var(--card-scroller--expand-bottom) * -1);
  padding: calc(var(--card-scroller--padding-top) + var(--card-scroller--expand-top))
    var(--card-scroller--padding-x)
    calc(var(--card-scroller--padding-bottom) + var(--card-scroller--expand-bottom))
    var(--card-scroller--padding-x);

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar in webkit-based browsers */
  }

  .hasPopups > &:has([data-card-popup-visible='true'], [data-batch-scrolling='true']) {
    contain: none;
  }

  /*
  Force grabbing cursor during mouse swipe and don't allow clicks going through.
  */
  &[data-mouse-swipe='true'] {
    cursor: grabbing;

    & * {
      cursor: grabbing !important;
    }

    & button,
    & a {
      pointer-events: none !important;
    }
  }
}

.content {
  pointer-events: auto;

  display: grid;
  grid-template-columns: repeat(var(--card-scroller--item-count), var(--card-scroller--card-width));
  gap: var(--g--card-gap);

  width: calc(
    (var(--card-scroller--item-count) * var(--card-scroller--card-width)) +
      (var(--g--card-gap) * (var(--card-scroller--item-count) - 1))
  );
  height: var(--card-scroller--card-height);
}

.cardSlot {
  --card-slot--index: 0;

  scroll-snap-align: start;
  content-visibility: hidden;
  contain-intrinsic-size: var(--card-scroller--card-width) var(--card-scroller--card-height);

  position: relative;

  grid-column: var(--card-slot--index, auto);

  width: var(--card-scroller--card-width);
  height: var(--card-scroller--card-height);

  &.isVisible {
    content-visibility: visible;
  }
}

.next,
.prev {
  contain-intrinsic-size: calc(var(--card-scroller--padding-x) - var(--g--card-gap))
    var(--card-scroller--height);

  position: absolute;
  z-index: 2;
  top: 0;

  contain: strict;

  width: calc(var(--card-scroller--padding-x) - var(--g--card-gap));
  height: var(--card-scroller--height);
  padding-top: var(--card-scroller--padding-top);
  padding-bottom: var(--card-scroller--padding-bottom);

  opacity: 0;

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  html[data-nav-device='mouse'] .root:hover > &.isActive {
    opacity: 1;
  }
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.scrollButton.scrollButton {
  width: 100%;
  height: 100%;
  padding: 0;

  background: var(--g--glass-dark-900);
  backdrop-filter: none;
  border-radius: var(--g--card-border-radius);

  .next > & {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .prev > & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > svg {
    width: 32px;
    height: 32px;
  }
}

.scrollIndicator {
  pointer-events: none;
  contain-intrinsic-size: var(--card-scroller--indicator-width)
    var(--card-scroller--indicator-height);

  position: absolute;
  inset: var(--card-scroller--indicator-top) var(--card-scroller--indicator-right)
    var(--card-scroller--indicator-bottom) var(--card-scroller--indicator-left);
  transform: scale(0.85);

  contain: strict;

  width: var(--card-scroller--indicator-width);
  height: var(--card-scroller--indicator-height);

  opacity: 0;
  background-color: rgb(255 255 255 / 20%);
  border-radius: 2px;

  transition:
    opacity var(--g--anim-duration) var(--g--anim-easing),
    transform var(--g--anim-duration) var(--g--anim-easing);

  html[data-nav-device='mouse'] .root:hover > &.isActive,
  html[data-nav-device='keyboard'] .root:focus-within > &.isActive {
    transform: scale(1);
    opacity: 1;
  }
}

.scrollIndicatorThumb {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(
    calc(
      (
          var(--card-scroller--indicator-width) -
            (var(--card-scroller--indicator-width) * var(--card-scroller--indicator-scroll-factor))
        ) *
        var(--card-scroller--indicator-thumb-position)
    )
  );

  width: calc(
    var(--card-scroller--indicator-width) * var(--card-scroller--indicator-scroll-factor)
  );
  height: var(--card-scroller--indicator-height);

  background-color: rgb(255 255 255 / 75%);
  border-radius: inherit;
}
